import { useCallback, useEffect, useRef, useState } from 'react';

export function useClipboard({ reset }: { reset?: number } = {}) {
  const [state, setState] = useState<'idle' | 'copied'>('idle'); // idle | copied
  const isMounted = useRef(false);
  const timer = useRef(null);

  // si desmontamos el componente, limpiamos el timer
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      clearTimeout(timer.current);
    };
  }, []);

  const copy = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text ?? '');
      setState('copied');
      timer.current = setTimeout(() => {
        if (!isMounted.current) return;
        setState('idle');
      }, reset);
    },
    [reset],
  );

  return { copy, state };
}
