import { useId, type PropsWithChildren } from 'react';
import { Button } from './button';
import { CloseModalSVG } from 'components/iconsSVG/close';
import { LabCloseIcon } from 'components/camperlab/icons/close';
import { getDomainValue } from 'utils/domain';

type CornerDialogProps = PropsWithChildren<{ label: string; onClose?: () => void }>;

export function CornerDialog({ label, onClose, children }: CornerDialogProps) {
  const id = useId();
  return (
    <div role="dialog" aria-labelledby={id} className="fixed bottom-2.5 right-2.5 z-[2200000000] w-[371px] bg-clear p-2.5">
      <div className={`flex items-center justify-between`}>
        <p
          id={id}
          className={`mb-0 text-sm font-bold uppercase lg:text-xs`}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
        <Button onPress={onClose} variant="none" className="flex items-center justify-center">
          <span className="sr-only">{'Close'}</span>
          {getDomainValue({ camper: <CloseModalSVG aria-hidden />, nnormal: <CloseModalSVG aria-hidden />, camperlab: <LabCloseIcon aria-hidden /> })}
        </Button>
      </div>
      {children}
    </div>
  );
}
